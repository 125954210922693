import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customCurrencyPipe'
})
export class CustomCurrencyPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) {
    }

    transform(value: any, fixed?: any, showMonetaryValue = true): string | null {
        const fixedValue = fixed ? fixed : 0;
        if (fixed === 0) {
            value = Number(this.toFixedCustom(value, fixedValue));
        } else {
            value = Number(value).toFixed(fixedValue);
        }

        if (showMonetaryValue) {
            return this.currencyPipe.transform(value, 'EUR', 'symbol', `1.${fixedValue}-${fixedValue}`)
                .replace(/\s/g, '');
        }
        return this.currencyPipe.transform(value, '', '', '1.0-2').replace(/\s/g, '');
    }

    toFixedCustom(num, fixed) {
        if (num) {
            const regex = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
            return num.toString().match(regex)[0];
        }
        return 0;
    }
}
