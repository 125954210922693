import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPageActionsModule } from '../core/components/custom-page-actions/custom-page-actions.module';
import { InputErrorModule } from '../core/components/input-error/input-error.module';
import { InputModule } from '../core/components/input/input.module';
import { AdvisePendentApprovalFoundModule } from '../core/components/notice-pendent-approval-found/notice-pendent-approval-found.module';
import { PageTitleModule } from '../core/components/page-title/page-title.module';
import { QuickSearchModule } from '../core/components/quick-search/quick-search.module';
import { SelectModule } from '../core/components/select/select.module';
import { TableModule } from '../core/components/table/table.module';
import { CookieBannerModule } from './components/cookie-banner/cookie-banner.module';
import { CookieDialogModule } from './components/cookie-dialog/cookie-dialog.module';
import { CreateCompanyCanteenModule } from './components/create-company-canteen-modal/create-company-canteen.module';
import { CreateCompanyUnitModule } from './components/create-company-unit-modal/create-company-unit.module';
import { CreateOptinModule } from './components/create-optin-modal/create-optin.module';
import { CreateUserModule } from './components/create-user-modal/create-user.module';
import { ExpandableCardRibbonModule } from './components/expandable-card-ribbon/expandable-card-ribbon.module';
import { InputFileModule } from './components/input-file/input-file.module';
import { InviteLinkModule } from './components/invite-link/invite-link.module';
import { LangSwitcherModule } from './components/lang-switcher/lang-switcher.module';
import { ListCompanyCanteensModule } from './components/list-company-canteens/list-company-canteens.module';
import { ListCompanyUnitsModule } from './components/list-company-units/list-company-units.module';
import { SpeechBubbleTabModule } from './components/speech-bubble-tab/speech-bubble-tab.module';
import { ToggleDropModule } from './components/toggle-drop/toggle-drop.module';
import { FilterActiveItemsPipe } from './pipes/filter-active-items.pipe';
import { FilterPermissionsFromUserPipe } from './pipes/filter-permissions-from-user.pipe';
import { FindLanguageFromKeyPipe } from './pipes/find-language-from-key.pipe';
import { FirebaseDatePipe } from './pipes/firebase-date.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { TransformListToStringPipe } from './pipes/transform-list-to-string.pipe';
import {ChangePasswordDialogModule} from "./components/change-password-dialog/change-password-dialog.module";
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';

@NgModule({
    declarations: [
        FindLanguageFromKeyPipe,
        TransformListToStringPipe,
        PhonePipe,
        FilterPermissionsFromUserPipe,
        FilterActiveItemsPipe,
        FirebaseDatePipe,
        CustomCurrencyPipe,
    ],
    imports: [
        TranslateModule,
        CommonModule,
        NgSelectModule,
        NgOptionHighlightModule,
        TableModule,
        AdvisePendentApprovalFoundModule,
        InputModule,
        InputErrorModule,
        SelectModule,
        QuickSearchModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        CookieBannerModule,
        CookieDialogModule,
        InviteLinkModule,
        ExpandableCardRibbonModule,
        ToggleDropModule,
        InputFileModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FindLanguageFromKeyPipe,
        TranslateModule,
        PageTitleModule,
        TableModule,
        AdvisePendentApprovalFoundModule,
        InputModule,
        SelectModule,
        NgSelectModule,
        NgOptionHighlightModule,
        InputErrorModule,
        QuickSearchModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        TransformListToStringPipe,
        CreateUserModule,
        CreateOptinModule,
        CookieBannerModule,
        CreateCompanyCanteenModule,
        CreateCompanyUnitModule,
        ListCompanyCanteensModule,
        ListCompanyUnitsModule,
        CookieBannerModule,
        SpeechBubbleTabModule,
        InviteLinkModule,
        ExpandableCardRibbonModule,
        ToggleDropModule,
        PhonePipe,
        CustomCurrencyPipe,
        LangSwitcherModule,
        InputFileModule,
        FilterPermissionsFromUserPipe,
        FirebaseDatePipe,
        FilterActiveItemsPipe,
        CustomPageActionsModule,
        ChangePasswordDialogModule
    ],
    providers: [
        CurrencyPipe,
    ]
})
export class SharedModule {
}
